// Moving forward, keys should be put here
export enum LocalStorageKey {
  ACCESS_TOKEN = 'access_token',
  FROM_MWELL_APP = 'from_mwell_app',
  HIDE_NAVIGATION = 'hide_navigation',
  LOGIN_OTP_DETAILS = 'login_otp_details',
  OTP_TOKEN = 'otp_token',
  POSITION = 'position',
  REGISTRATION_OTP_DETAILS = 'registration_otp_details',
  REGISTRATION_VERIFICATION_DETAILS = 'registration_verification_details',
  USER = 'user',
  USER_ABILITIES = 'user_abilities',
  IS_MPH_BOOKING = 'is_mph_booking',
  MPH_BOOKING_PROVIDER_ID = 'mph_booking_provider_id',
  IS_HMO = 'is_hmo',
  IS_MEMBERSHIPS = 'is_memberships',
  MPH_APPOINTMENT_ID = 'mph_appointment_id',
}

export enum PersistentLocalStorageKey {
  LAST_FEEDBACK_CLOSED_TIME = 'last_feedback_closed_time',
}
