import Script from 'next/script';

export function NetcoreSmartechInit({ ...props }) {
  const { createKey, registerKey } = props;

  return (
    <>
      <Script src="//cdnt.netcoresmartech.com/smartechclient.js" strategy="beforeInteractive" />
      <Script id="netcore-smartech-init" strategy="afterInteractive">
        {`
            if (typeof window.smartech !== 'undefined') {
              smartech(
                'create',
                '${createKey}',
              );
              smartech('register', '${registerKey}');
            }
            function hanselEventsListener(eventName, eventData) {
              if (typeof window.smartech !== 'undefined') {
                smartech('dispatch', eventName, eventData);
              }
            }
            setTimeout(function() {
              if (typeof Hansel != 'undefined' && Hansel.registerListener) {
                Hansel.registerListener(hanselEventsListener);
              }
            }, 3000);
          `}
      </Script>
    </>
  );
}
