import { forwardRef, HTMLProps, Ref } from 'react';

import { CheckCircleIcon } from '@heroicons/react/20/solid';

import { VariantType } from '../../enums';
import { clsxMerge } from '../../utils';
import LoadingSpinner from '../LoadingSpinner';

const BUTTON_SIZE_CLASSES = {
  xxs: 'rounded px-1 h-6 text-xs',
  xs: 'rounded px-2 h-8 text-xs',
  sm: 'rounded px-2 h-9 text-sm',
  md: 'rounded-lg px-2.5 h-10 text-sm',
  lg: 'rounded-lg px-3 h-12 text-sm',
  xl: 'rounded-lg px-3.5 h-14 text-sm',
} as const;

const ICON_BUTTON_SIZE_CLASSES = {
  xxs: 'h-7 w-7',
  xs: 'h-8 w-8',
  sm: 'h-9 w-9',
  md: 'h-10 w-10',
  lg: 'h-12 w-12',
  xl: 'h-14 w-14',
} as const;

const ICON_SIZE_CLASS = {
  xs: 'w-3 h-3',
  sm: 'w-4 h-4',
  md: 'w-5 h-5',
  lg: 'w-6 h-6',
  xl: 'w-7 h-7',
} as const;

const BUTTON_VARIANT_CLASSES = {
  primary: 'bg-primary-500 text-neutral-50 hover:bg-primary-400',
  primaryLight: 'bg-primary-500 text-white hover:bg-primary font-medium',
  primarySoft: 'bg-primary1 bg-opacity-[.12] text-primary3 text-sm font-medium leading-normal',
  primarySoft2: 'bg-primary1 bg-opacity-[.12] text-primary-500 text-base leading-normal',
  primarySoft3: 'bg-white bg-opacity-[.12] text-primary-500 text-base leading-normal',
  primary50: 'bg-primary50 text-primary2',
  primary10: 'bg-primary10 text-white hover:bg-primary2',
  secondary: 'bg-secondary-300 text-primary-500 hover:bg-secondary-400',
  secondaryOutline:
    'bg-secondary-300 text-primary-500 hover:bg-secondary-400 outline outline-1 outline-primary-500',
  tertiary:
    'bg-transparent text-primary-500 hover:bg-secondary-300 focus:ring-2 focus:ring-secondary-400 disabled:text-neutral-300',
  secondaryGray:
    'outline outline-1 outline-neutral-300 bg-neutral-50 text-neutral-500 hover:bg-neutral-300 disabled:border-neutral-300 disabled:bg-neutral-200 disabled:text-neutral-400',
  error: 'bg-red-600 text-white hover:bg-red-700',
  label: 'text-primary1 hover:bg-gray-50',
  cart: 'outline outline-1 outline-healeeBlue bg-white text-healeeBlue hover:bg-healeeBlue hover:text-white disabled:border-gray28 disabled:bg-gray11 disabled:text-gray9',
  outlined:
    'outline outline-1 outline-neutral-400 bg-white text-graniteGray font-normal hover:bg-gray24 disabled:border-gray24 disabled:bg-gray11 disabled:text-gray9',
  outlinedRed:
    'outline outline-1 outline-red-700 bg-white text-red-700 font-normal hover:bg-red-700 hover:text-white disabled:border-gray24 disabled:bg-gray11 disabled:text-gray9',
  outlinedPrimary:
    'outline outline-1 outline-primary-500 bg-white text-primary-500 font-normal hover:bg-gray24 disabled:border-gray24 disabled:bg-gray11 disabled:text-gray9',
  plainNoOutline:
    'p-0 bg-none outline-none text-primary-500 text-sm font-normal hover:text-primary-400',
  disabled: 'text-white bg-disabled outline outline-1 outline-disabled',
  successPrimary: 'bg-green-700 text-neutral-50',
  plain: '',
  gradient: '!bg-size-200 hover:bg-pos-100 bg-pos-0',
  green: 'bg-green-700 text-white hover:bg-green-600',
} as const;

export type IconSize = keyof typeof ICON_SIZE_CLASS;

export type ButtonSize = keyof typeof BUTTON_SIZE_CLASSES;

export type ButtonVariant = keyof typeof BUTTON_VARIANT_CLASSES | VariantType;

export type ButtonType = 'button' | 'reset' | 'submit';

export interface ButtonProps
  extends Omit<HTMLProps<HTMLButtonElement | HTMLAnchorElement>, 'type' | 'size'> {
  as?: 'a' | 'button';
  type?: ButtonType;
  size?: ButtonSize;
  variant?: ButtonVariant;
  icon?: React.ElementType;
  iconSize?: IconSize;
  iconRight?: React.ElementType;
  isLoading?: boolean;
  isSuccess?: boolean;
  className?: string;
}

function Button(props: ButtonProps, forwardedRef?: Ref<any>) {
  const {
    as = 'button',
    children,
    size = 'md',
    variant = 'secondary',
    className,
    type = 'button',
    icon: Icon,
    iconSize = 'md',
    iconRight: IconRight,
    isLoading,
    isSuccess,
    ...etcProps
  } = props;

  const iconOnly = !children && !!Icon;

  const Component = as;

  return (
    <Component
      disabled={isLoading && as === 'button'}
      {...etcProps}
      ref={forwardedRef}
      type={as === 'button' ? type : undefined}
      className={clsxMerge(
        'inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium transition-all duration-300 ease-in-out disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500',
        BUTTON_VARIANT_CLASSES[variant],
        iconOnly ? `!rounded-full ${ICON_BUTTON_SIZE_CLASSES[size]}` : BUTTON_SIZE_CLASSES[size],
        {
          'px-6 py-2.5': children,
          'bg-green3 text-gray23 outline outline-1 outline-gray30 disabled:border-gray30 disabled:bg-green3 disabled:text-gray23':
            isSuccess,
        },
        className,
      )}
    >
      {isLoading && <LoadingSpinner className="mr-2" size="md" />}
      {isSuccess && <CheckCircleIcon className="mr-2 w-[18px]" color="#39BD56" />}
      {Icon && <Icon className={clsxMerge({ 'mr-2 h-6': children }, ICON_SIZE_CLASS[iconSize])} />}
      {children}
      {IconRight && (
        <IconRight className={clsxMerge({ 'ml-2': children }, ICON_SIZE_CLASS[iconSize])} />
      )}
    </Component>
  );
}

export default forwardRef(Button);
