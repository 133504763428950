import { md5 } from 'js-md5';
import isEmpty from 'lodash.isempty';
import { v4 as uuidv4 } from 'uuid';

import { capitalize } from './common';
import { FileTypeExtensionEnum } from '../constants';

const WELLNESS_PASS_CODE_INDICATOR = 'Wellness Pass Code:';

export const replaceNullOrEmptyWithNA = (str: string | null | undefined) => {
  if (str === null || str === undefined || str === '') {
    return 'N/A';
  }

  return str;
};

export const toTitleCase = (str: string) => {
  if (isEmpty(str)) {
    str = '';
  }
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

export const shortenDay = (str: string) => {
  return str.substring(0, 3);
};

export const transformBooleanToString = (bool?: boolean) => {
  return bool ? 'true' : 'false';
};

export const randomString = (length = 6, pool = '1234567890') => {
  let str = '';
  for (let i = 0; i < length; i++) {
    str += pool[Math.floor(Math.random() * pool.length)];
  }
  return str;
};

export const generateUUID = () => {
  return uuidv4();
};

export const isString = (value: unknown): boolean => {
  return typeof value === 'string';
};

export const formatFileTypesForLabel = (fileTypes: FileTypeExtensionEnum[]) => {
  return fileTypes
    .map((fileType) => `.${fileType}`)
    .join(',')
    .toUpperCase();
};

export const renderDynamicData = (data?: string | number) => {
  return data || '--';
};

export const formatStringToMD5 = (inputString: string) => {
  const hash = md5.create();

  hash.update(inputString);

  return hash.hex();
};

export const extractWellnessPassCode = (appointmentNote: string) => {
  if (!appointmentNote) {
    return '';
  }

  const regex = /Wellness Pass Code: (.*)/;
  const match = appointmentNote.match(regex);

  return match ? match[1].trim() : '';
};

export const extractAppointmentNotes = (appointmentNote: string) => {
  const index = appointmentNote.indexOf(WELLNESS_PASS_CODE_INDICATOR);

  return index !== -1 ? appointmentNote.substring(0, index).trim() : appointmentNote;
};

export const appendWellnessPassCode = (appointmentNote: string, wellnessPassCode: string) => {
  const passCodeWithIndicator = `${WELLNESS_PASS_CODE_INDICATOR} ${wellnessPassCode}`;
  const trimmedAppointmentNote = appointmentNote.trim();

  return trimmedAppointmentNote
    ? `${trimmedAppointmentNote}\n${passCodeWithIndicator}`
    : passCodeWithIndicator;
};

export const truncateMiddle = (input: string, maxLength = 35): string => {
  if (input.length <= maxLength) {
    return input;
  }

  const middle = maxLength / 2;
  const start = input.slice(0, middle - 1);
  const end = input.slice(-middle + 2);

  return `${start}...${end}`;
};

export const truncateLastChars = (input: string, visibleChars = 100): string => {
  if (input.length <= visibleChars) {
    return input;
  }

  return `${input.substring(0, visibleChars)}...`;
};

export const maskStringExceptLastChars = (input: string, visibleChars = 4): string => {
  if (input.length <= visibleChars) {
    return input;
  }

  const maskLength = input.length - visibleChars;

  return `${'*'.repeat(maskLength)}${input.substring(maskLength)}`;
};

export function convertCamelCaseToTitle(camelCaseString: string) {
  const result = camelCaseString
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase());
  return result;
}

export function getTextFromHtmlString(value: string) {
  return value.replace(/<[^>]*>/g, '');
}

export const renderFormattedDays = (days: string[]) => {
  return days.map((day) => capitalize(day.slice(0, 3).toLocaleLowerCase())).join(', ');
};

export const joinStringWithDelimiter = (values: string[], delimiter: string) => {
  return values.filter(Boolean).join(delimiter);
};
